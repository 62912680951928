import React from 'react';
import { Link } from 'react-router-dom';

// Images
import heroBannerImage from '../assets/nationbuilder-to-hubspot-image.jpeg';
import authenticateNationBuilderImage from '../assets/authenticate-nationbuilder.jpeg';
import syncSuccess from '../assets/sync-success.jpeg';

const pageTitle = 'Mosaic NB to HubSpot';

const Home: React.FC = () => {
  React.useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <>
      {/*  Hero Banner */}
      <section className="bg-teal-700 text-white text-left md:text-left">
        <div className="container max-w-7xl py-10 md:py-24 px-5 mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          <div>
            <h1 className="text-2xl md:text-4xl mb-3 md:mb-4 font-semibold text-left md:text-left text-white">
              Nationbuilder to HubSpot Contact Migration App By Mosaic
            </h1>
            <p className="text-md pb-5">
              Are you a NationBuilder user who has built up a contact list
              through your website but now wants to extend your digital
              marketing with the power of HubSpot? Mosaic's NationBuilder to
              HubSpot Contact Migration App makes this process as simple as
              clicking your mouse!
            </p>
            <Link
              to={'/hubspot'}
              className="inline-block py-3 px-6 border rounded border-white bg-white font-semibold text-teal-700"
            >
              Install Now
            </Link>
          </div>
          <div className="bg-slate-100 border-opacity-0 rounded shadow-md shadow-teal-900/80 overflow-hidden">
            <img
              src={heroBannerImage}
              className="md:min-h-full md:object-cover"
              alt="NationBuilder to HubSpot"
            />
          </div>
        </div>
      </section>
      <section className="text-left text-slate-700">
        <div className="container max-w-7xl py-10 md:py-16 px-5 mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          <div className="bg-slate-100 border-opacity-0 rounded overflow-hidden shadow-lg shadow-slate-200/90">
            <img
              src={authenticateNationBuilderImage}
              className="md:min-h-full md:object-cover"
              alt="Authenticate NationBuilder"
            />
          </div>
          <div>
            <h2 className="text-1xl md:text-3xl mb-3 md:mb-4 font-semibold">
              Pull Your NationBuilder Data
            </h2>
            <p className="text-md text-left md:text-left pb-5">
              The NationBuilder to HubSpot Contact Migration App by Mosaic makes
              moving your people database to HubSpot a cinch. There is no need
              to export your data to a .csv file and spend countless hours
              combing over your spreadsheet to ensure it is formatted correctly
              for an import. We provide step-by-step instructions on how to set
              up your NationBuilder account to connect for the data exchange
              through our app, easy peasy!
            </p>
          </div>
        </div>
      </section>
      <section className="text-center md:text-left text-slate-700">
        <div className="container max-w-7xl py-10 md:py-16 px-5 mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          <div>
            <h2 className="text-1xl md:text-3xl mb-3 md:mb-4 font-semibold">
              Import Data Into HubSpot
            </h2>
            <p className="text-md text-left md:text-left pb-5">
              You have done the quick and easy steps outlined and are now ready
              to import data. Now what? To sync your NationBuilder people
              records to your HubSpot contacts, click the "Sync People To
              Contacts" button. And your data sync has begun!
            </p>
            <p className="text-md text-left md:text-left pb-5">
              Now you are asking, "how do I know that worked?" Go to your
              contacts list in HubSpot, and you will see a new custom field
              called "NationBuilder People ID" in your contact record.
            </p>
            <p className="text-md text-left md:text-left pb-5">
              Now what? Get ready to experience the awesome power of digital
              marketing and other power tools in the HubSpot platform to expand
              your engagement, build trust with your audience, and convert more
              donors or sales.
            </p>
          </div>
          <div className="bg-slate-100 border-opacity-0 rounded overflow-hidden shadow-lg shadow-slate-200/90">
            <img
              src={syncSuccess}
              className="md:min-h-full md:object-cover"
              alt="Authenticate NationBuilder"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
