export const API_ROUTES = {
  HUBSPOT: {
    AUTHORIZATION_URL: '/api/v1/hubspot/authorization-url',
    TOKEN: '/api/v1/hubspot/token',
  },
  NATION_BUILDER: {
    AUTHORIZATION_URL: '/api/v1/nationbuilder/authorization-url',
    TOKEN: '/api/v1/nationbuilder/token',
    PEOPLE_COUNT: '/api/v1/nationbuilder/people-count',
    PEOPLE: '/api/v1/nationbuilder/people',
  },
  SYNC: '/api/v1/sync',
};
