import React from 'react';
import { Link } from 'react-router-dom';

const TealButtonLink: React.FC<{ children: React.ReactNode; to: string }> = ({
  to,
  children,
}) => {
  return (
    <Link
      to={to}
      className="inline-block font-semibold px-6 py-3 focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 leading-none border rounded text-white border-teal-700 bg-teal-700 hover:text-white hover:bg-teal-800 hover:bg-teal-800"
    >
      {children}
    </Link>
  );
};

export default TealButtonLink;
