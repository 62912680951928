import React from 'react';

const SuccessAlertBar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="bg-green-300 text-center py-4 lg:px-4">
      <div
        className="p-2 bg-green-600 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
        role="alert"
      >
        <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
          Success
        </span>
        <span className="font-semibold mr-2 text-left flex-auto">
          {children}
        </span>
      </div>
    </div>
  );
};

export default SuccessAlertBar;
