import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { APIResponse } from '../../../types';

interface NationBuilderFormState extends Record<string, string> {
  client_id: string;
  client_secret: string;
  nation_slug: string;
}

type NationBuilderAuthorizationURL = string;

interface FormSubmitOnSuccessCallback {
  (options: {
    authenticationURL: string;
    formState: NationBuilderFormState;
  }): void;
}

const defaultFormSubmitOnSuccess: FormSubmitOnSuccessCallback = (options) => {};

const useNationBuilderFormState = (
  { onSuccess } = {
    onSuccess: defaultFormSubmitOnSuccess,
  }
) => {
  const [formState, setFormState] = React.useState<NationBuilderFormState>({
    client_id: '',
    client_secret: '',
    nation_slug: '',
  });

  const { mutate, status } = useMutation<
    APIResponse<NationBuilderAuthorizationURL>,
    any,
    NationBuilderFormState
  >(
    (formData) =>
      fetch(
        '/api/v1/nationbuilder/authorization-url?' +
          new URLSearchParams(formData),
        {
          method: 'GET',
        }
      )
        .then((res) => {
          return res.json();
        })
        .catch((err) => console.error(err)),
    {
      onSuccess: (successResponse, formState) => {
        onSuccess({
          authenticationURL: successResponse.success
            ? successResponse.data
            : '',
          formState,
        });
      },
    }
  );

  return {
    formState,
    setFormState,
    submitForm: mutate,
    formSubmitStatus: status,
  };
};

export default useNationBuilderFormState;
