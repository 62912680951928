import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import TealButtonLink from './TealButtonLink';

const Nav: React.FC = () => {
  const [isMobileNavOpen, setIMobileNavOpen] = React.useState(false);

  const onMobileNavMenuToggleClick = () => {
    setIMobileNavOpen((isMobileNavOpen) => !isMobileNavOpen);
  };

  return (
    <nav className="flex items-center justify-between flex-wrap bg-white border-b-2 border-slate-50 p-6 px-5">
      <Link
        to="/"
        className="flex items-center flex-shrink-0 text-teal-700 mr-6"
      >
        <span className="font-semibold text-xl tracking-tight">
          <span className="inline-block font-bold text-2xl mr-2">Mosaic</span>{' '}
          <span className="inline-block">
            NB{' '}
            <span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
              </svg>
              <span className="sr-only">To</span>
            </span>{' '}
            HubSpot
          </span>
        </span>
      </Link>
      <div className="block lg:hidden">
        <button
          onClick={onMobileNavMenuToggleClick}
          className="flex items-center text-teal-700 py-1 px-2 border-teal-700 focus:outline focus:outline-dashed rounded outline-offset-2 outline-2 focus:outline-teal-700/50"
        >
          <svg
            className="fill-current h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className="w-full hidden lg:block text-md flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-right lg:flex-grow">
          <NavLink
            to="/"
            className="block font-semibold focus:underline focus:underline-offset-4 mt-4 mr-0 lg:inline-block lg:mt-0 text-teal-700 md:mr-8"
          >
            Home
          </NavLink>
          <NavLink
            to="/docs"
            className="block font-semibold focus:underline focus:underline-offset-4 mt-4 mr-0 lg:inline-block lg:mt-0 text-teal-700 md:mr-8"
          >
            Docs
          </NavLink>
        </div>
        <div>
          <TealButtonLink to={'/hubspot'}>Install</TealButtonLink>
        </div>
      </div>
      {isMobileNavOpen && (
        <div className="w-full block lg:hidden mt-4 lg:mt-0 text-md flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-left lg:text-right lg:flex-grow border-t-2 border-t-slate-200 lg:border-t-0">
            <NavLink
              to="/"
              className="block font-semibold focus:underline focus:underline-offset-4 mt-4 mr-0 lg:inline-block lg:mt-0 text-teal-700 md:mr-8"
            >
              Home
            </NavLink>
            <NavLink
              to="/docs"
              className="block font-semibold focus:underline focus:underline-offset-4 mt-4 mr-0 lg:inline-block lg:mt-0 text-teal-700 md:mr-8"
            >
              Docs
            </NavLink>
          </div>
          <div className="text-left mt-4 pt-4 lg:mt-0 lg:pt-0 border-t-2 border-t-slate-100">
            <TealButtonLink to={'/hubspot'}>Install</TealButtonLink>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nav;
