import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Routes
import Home from './routes/Home';
import Docs from './routes/Docs';
import Hubspot from './routes/Hubspot';
import NationBuilder from './routes/NationBuilder/NationBuilder';
import NationBuilderOAuth from './routes/NationBuilderOAuth/NationBuilderOAuth';
import HubspotOAuth from './routes/HubspotOAuth';
import FourOhFour from './routes/FourOhFour';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermsOfService from './routes/TermsOfService';

// Components
import Nav from './components/Nav';
import Footer from './components/Footer';

import './App.css';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Nav />
      <main className={'relative body-container mx-auto'}>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            {/*<Route path="login" element={<Login />} />*/}
            <Route path="hubspot">
              <Route index element={<Hubspot />} />
              <Route path="oauth-callback" element={<HubspotOAuth />} />
            </Route>
            <Route path="nationbuilder">
              <Route index element={<NationBuilder />} />
              <Route path="oauth-callback" element={<NationBuilderOAuth />} />
            </Route>
            <Route path="docs" element={<Docs />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
          </Route>
          <Route path="*" element={<FourOhFour />} />
        </Routes>
      </main>
      <ReactQueryDevtools initialIsOpen={false} />
      <Footer />
    </QueryClientProvider>
  );
}

export default App;
