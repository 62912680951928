import React from 'react';

/**
 * Ensure you pass a static string (use a const outside of your Component)
 *
 * @param title
 */
const useUpdateDocumentTitle = (title: string) => {
  React.useEffect(() => {
    document.title = `${title} - Mosaic NB to HubSpot`;
  }, [title]);
};

export default useUpdateDocumentTitle;
