import React from 'react';
import useUpdateDocumentTitle from '../hooks/useUpdateDocumentTitle';

const pageTitle = '404';

const FourOhFour: React.FC = () => {
  useUpdateDocumentTitle(pageTitle);

  return (
    <div className={'my-16 mx-auto py-4'}>
      <h1 className="text-4xl mb-4 font-semibold">Page Not Found</h1>
      <p className="text-md pb-2">
        This is not the page you are looking for!
      </p>
    </div>
  );
};

export default FourOhFour;
