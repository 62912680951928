import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="footer border-t-2 border-t-gray-50">
      <div className="flex container max-w-7xl mx-auto align-middle justify-center md:justify-end text-sm text-teal-800 py-8 px-5">
        <Link to="/terms-of-service" className="inline-block mr-2 md:mr-4">
          Terms of Service
        </Link>
        <span className="not-sr-only text-black text-md">•</span>
        <Link to="/privacy-policy" className="inline-block ml-2 md:ml-4">
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
