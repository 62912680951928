import React from 'react';

const LoadingAlertBar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="bg-orange-100 text-center py-4 max-w-5xl m-5 mx-auto px-4 sm:px-6 rounded-lg">
      <div
        className="p-2 bg-orange-400 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
        role="alert"
      >
        <span className="flex rounded-full bg-orange-600 uppercase px-2 py-1 text-xs font-bold mr-3">
          Loading
        </span>
        <span className="font-semibold mr-2 text-left flex-auto">
          {children}
        </span>
      </div>
    </div>
  );
};

export default LoadingAlertBar;
