import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '../../../../../api';

// Types
import { APIResponse } from '../../../../../types';
import { NationBuilderPeopleCount } from '../../../types';

const usePeopleCount = () => {
  const { data, error, status } = useQuery(
    ['people-count'],
    () =>
      fetch(API_ROUTES.NATION_BUILDER.PEOPLE_COUNT, { method: 'GET' }).then<
        APIResponse<NationBuilderPeopleCount>
      >((res) => res.json()),
    {
      // Don't refetch for Token
      refetchOnWindowFocus: false,
      // Don't refetch on mount
      refetchOnMount: false,
    }
  );

  return { data, error, status };
};

export default usePeopleCount;
