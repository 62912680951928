import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Components
import LoadingAlertBar from '../components/LoadingAlertBar';
import SuccessAlertBar from '../components/SuccessAlertBar';
import ErrorAlertBar from '../components/ErrorAlertBar';

// Types
import { APIResponse } from '../types';
import useUpdateDocumentTitle from '../hooks/useUpdateDocumentTitle';

interface HubspotToken {
  refreshToken: string;
  accessToken: string;
  expiresIn: number;
  tokenType: 'bearer';
  updatedAt: number;
}

const fetchAccessToken = (code: string) => {
  return fetch(`/api/v1/hubspot/token?code=${code}`, {
    method: 'GET',
  }).then<APIResponse<HubspotToken>>((res) => {
    return res.json();
  });
};

const useGetAccessToken = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') as string;
  const { data, error, isLoading, isLoadingError, isError } = useQuery(
    ['token', code],
    () => fetchAccessToken(code),
    {
      // Only retrieve the token if there's a `code` URL query param
      enabled: !!code,
      // Don't refetch for Token
      refetchOnWindowFocus: false,
      // Don't refetch on mount
      refetchOnMount: false,
    }
  );

  return {
    data,
    error,
    isLoading,
    isLoadingError,
    isError,
  };
};

const pageTitle = 'HubSpot Install';

const HubspotOAuth: React.FC = () => {
  const { data } = useGetAccessToken();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useUpdateDocumentTitle(pageTitle);

  React.useEffect(() => {
    const installCode = searchParams.get('code');

    if (!installCode) {
      navigate('/hubspot', { replace: true });
    }
  }, [searchParams, navigate]);

  // Loading state
  if (!data) {
    return (
      <LoadingAlertBar>
        <strong>HubSpot:</strong> attempting to verify authentication code.
      </LoadingAlertBar>
    );
  }

  // Error state
  if (!data.success && data.hasOwnProperty('message')) {
    return (
      <>
        <ErrorAlertBar>
          <strong>HubSpot:</strong> {data.message}
        </ErrorAlertBar>
        <Link
          to={'/hubspot'}
          className="group mt-8 relative inline-block shadow-sm justify-center py-2 px-4 border border-transparent font-medium rounded text-white bg-teal-700 hover:bg-teal-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
        >
          Re-authenticate HubSpot
        </Link>
      </>
    );
  }

  return (
    <>
      <SuccessAlertBar>
        <strong>HubSpot:</strong> you have successfully installed our App!
      </SuccessAlertBar>
      <div className="block my-4 py-4 max-w-lg mx-auto">
        <h1 className="text-3xl mb-4">Hooray!</h1>
        <p className="pb-4">
          Now that you've authenticated and installed our app on HubSpot it's
          now time to authenticate NationBuilder.
        </p>
        <p className="pb-4">Click the button below to do so.</p>
        <Link
          to={'/nationbuilder'}
          className="inline-block relative shadow-sm justify-center py-2 px-4 border border-transparent font-medium rounded text-white bg-teal-700 hover:bg-teal-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
        >
          Authenticate NationBuilder
        </Link>
      </div>
    </>
  );
};

export default HubspotOAuth;
