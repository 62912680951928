import React from 'react';
import { Link } from 'react-router-dom';
import useUpdateDocumentTitle from '../hooks/useUpdateDocumentTitle';

const pageTitle = 'Docs';

const Docs: React.FC = () => {
  useUpdateDocumentTitle(pageTitle);

  return (
    <>
      <section className="text-left">
        <div className="container max-w-7xl pt-8 md:pt-16 px-5 mx-auto">
          <h1 className="text-2xl md:text-4xl font-semibold border-b-2 border-b-slate-100 pb-2">
            Documentation
          </h1>
        </div>
      </section>
      <section className="text-left ">
        <div className="container max-w-7xl py-8 md:py-16 px-5 mx-auto">
          <h2 className="text-xl md:text-2xl mb-8">App install and setup</h2>
          <h3 className="text-lg mb-4">
            <span className="font-semibold">Step 0:</span> Create and register
            an app on NationBuilder
          </h3>
          <ol className="list-decimal list-outside pl-5">
            <li className="pb-2">Create an app on your nation:</li>
            <ul className="list-disc list-outside pl-8">
              <li className="pb-2">Log into your nation.</li>
              <li className="pb-2">
                Navigate to <span className="font-semibold">Settings</span>{' '}
                {'>'} <span className="font-semibold">Developer</span> {'>'}{' '}
                <span className="font-semibold">Register New App</span>.
              </li>
              <li className="pb-2">Register your application with a name.</li>
              <ul className="list-disc list-outside pl-8">
                <li className="pb-2">
                  Any name you works here but we think{' '}
                  <code className="bg-slate-200 tracking-tight text-sm px-1">
                    NationBuilder to HubSpot
                  </code>{' '}
                  would be cool here :).
                </li>
              </ul>
              <li className="pb-2">
                Set the OAuth callback URL to{' '}
                <code className="bg-slate-200 tracking-tight text-sm px-1">
                  https://www.mosaicapps.io/nationbuilder/oauth-callback
                </code>
                .
              </li>
            </ul>
            <li className="pb-2">
              Be sure to have the{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                Client ID
              </code>{' '}
              and{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                Client Secret
              </code>{' '}
              your nation slug handy for the remaining steps.
            </li>
          </ol>
          <hr className="border-b border-b-slate-100 my-5" />
          <h3 className="text-lg mb-4">
            <span className="font-semibold">Step 1:</span> Install on HubSpot
          </h3>
          <ol className="list-decimal list-outside pl-5">
            <li className="pb-2">
              Go to our{' '}
              <Link
                to="/hubspot"
                className="text-teal-700 underline underline-offset-2"
              >
                install page
              </Link>{' '}
              and click the{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                Authenticate HubSpot
              </code>{' '}
              button.
            </li>
            <li className="pb-2">
              Select the account you'd like to install the app.
            </li>
            <li className="pb-2">
              Once installed you'll be redirected back to our website
            </li>
            <li className="pb-2">
              Click the{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                Authenticate NationBuilder
              </code>{' '}
              button.
            </li>
          </ol>
          <hr className="border-b border-b-slate-100 my-5" />
          <h3 className="text-lg mb-4">
            <span className="font-semibold">Step 2:</span> Authenticate
            NationBuilder
          </h3>
          <p className="pb-4 block text-sm">
            If you haven't seen <strong>Step 0</strong> please refer back before
            continuing.
          </p>
          <ol className="list-decimal list-outside pl-5">
            <li className="pb-2">Fill out the form:</li>
            <ul className="list-disc list-outside pl-8">
              <li className="pb-2">Paste in your nation slug</li>
              <li className="pb-2">
                Paste in your NationBuilder app's{' '}
                <code className="bg-slate-200 tracking-tight text-sm px-1">
                  Client ID
                </code>
                .
              </li>
              <li className="pb-2">
                Paste in your NationBuilder app's{' '}
                <code className="bg-slate-200 tracking-tight text-sm px-1">
                  Client Secret
                </code>
                .
              </li>
            </ul>
            <li className="pb-2">
              Submit the form by click the{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                Authenticate
              </code>{' '}
              button.
            </li>
            <li className="pb-2">
              Once you're redirect to your nation on NationBuilder, please click
              the button{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                Authorize
              </code>
              .
            </li>
            <li className="pb-2">
              Once you've authorized you will be redirected back to our app.
            </li>
          </ol>
          <hr className="border-b border-b-slate-100 my-6" />
          <h3 className="text-lg mb-4">
            <span className="font-semibold">Step 3:</span> Sync NationBuilder
            People to HubSpot Contacts
          </h3>
          <ol className="list-decimal list-outside pl-5">
            <li className="pb-2">
              Click the{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                Sync People To Contacts
              </code>{' '}
              button to begin the sync
            </li>
            <ul className="list-disc list-inside pl-6">
              <li className="pb-2">
                <span className="font-bold">
                  NOTE: this is a one time sync ONLY!!
                </span>
              </li>
              <li className="pb-2 font-semibold">
                Currently, we only support a one time sync (without handling
                duplicates).
              </li>
            </ul>
            <li className="pb-2">
              To verify the sync affected your HubSpot account you should see a
              new field called{' '}
              <code className="bg-slate-200 tracking-tight text-sm px-1">
                NationBuilder People ID
              </code>{' '}
              in your Contacts list.
            </li>
          </ol>
        </div>
      </section>
    </>
  );
};

export default Docs;
