import React from 'react';
import { useQuery } from '@tanstack/react-query';

// Components
import ErrorAlertBar from '../components/ErrorAlertBar';
import { APIResponse } from '../types';
import { API_ROUTES } from '../api';
import useUpdateDocumentTitle from '../hooks/useUpdateDocumentTitle';

const fetchAuthorizationURL = () => {
  return fetch(API_ROUTES.HUBSPOT.AUTHORIZATION_URL, {
    method: 'GET',
  }).then<APIResponse<string>>((res) => {
    return res.json();
  });
};

const useAuthenticationURL = () => {
  const { data, error, status } = useQuery(
    ['authorization-url'],
    () => fetchAuthorizationURL(),
    {
      // Don't refetch for Token
      refetchOnWindowFocus: false,
      // Don't refetch on mount
      refetchOnMount: false,
    }
  );

  return {
    data,
    error,
    status,
  };
};

const pageTitle = 'HubSpot';

const Hubspot: React.FC = () => {
  const { data, status, error } = useAuthenticationURL();

  useUpdateDocumentTitle(pageTitle);

  return (
    <>
      {error && (
        <ErrorAlertBar>
          An error occurred when trying to retrieve the authorization URL. Try
          refreshing the page.
        </ErrorAlertBar>
      )}
      <div className={'my-16 flex-col align-middle justify-center py-4'}>
        <h1 className="text-4xl mb-4">Authenticate HubSpot</h1>
        <p className="text-md pb-2">
          The button below with redirect you to HubSpot to select an environment
          to install our app.
        </p>
        {status === 'success' && (
          <a
            href={data && data.success ? data.data : ''}
            className="inline-block mt-4 bg-teal-700 group relative shadow-sm justify-center py-2 px-4 border border-transparent font-medium rounded text-white bg-teal-700 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
          >
            Go to HubSpot
          </a>
        )}
      </div>
    </>
  );
};

export default Hubspot;
