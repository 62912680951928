import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Components
import LoadingAlertBar from '../../components/LoadingAlertBar';
import ErrorAlertBar from '../../components/ErrorAlertBar';
import SuccessAlertBar from '../../components/SuccessAlertBar';
import People from './components/People/People';

// Types
import { APIResponse } from '../../types';
import useUpdateDocumentTitle from '../../hooks/useUpdateDocumentTitle';

interface AuthToken {
  access_token: string;
  token_type: string;
  scope: string;
  created_at: number;
}

const fetchAccessToken = (code: string) => {
  return fetch(`/api/v1/nationbuilder/token?code=${code}`, {
    method: 'GET',
  }).then<APIResponse<AuthToken>>((res) => {
    return res.json();
  });
};

const useGetAccessToken = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') as string;
  const { data, error, isLoading, isLoadingError, isError, status } = useQuery(
    ['token', code],
    () => fetchAccessToken(code),
    {
      // Only retrieve the token if there's a `code` URL query param
      enabled: !!code,
      // Don't refetch for Token
      refetchOnWindowFocus: false,
      // Don't refetch on mount
      refetchOnMount: false,
    }
  );

  return {
    data,
    error,
    isLoading,
    isLoadingError,
    isError,
    status,
  };
};

const pageTitle = 'NationBuilder Install';

const NationBuilderOAuth = () => {
  const { data } = useGetAccessToken();

  useUpdateDocumentTitle(pageTitle);

  // Loading state
  if (!data) {
    return (
      <LoadingAlertBar>
        <strong>NationBuilder:</strong> attempting to verify authentication
        code.
      </LoadingAlertBar>
    );
  }

  // Error state
  if (!data.success && data.hasOwnProperty('message')) {
    return (
      <>
        <ErrorAlertBar>
          <strong>NationBuilder:</strong> {data.message}
        </ErrorAlertBar>
        <div className="block my-4 pt-4 max-w-screen-2xl mx-auto">
          <h1 className="text-3xl mb-4 font-semibold">Oh no!</h1>
          <p className="pb-4">
              An error occurred occurred when trying to authenticate NationBuilder.
          </p>
          <p className="pb-4">
            Click the button below to re-authenticate NationBuilder.
          </p>
          <Link
            to={'/nationbuilder'}
            className="group mt-2 my-12 font-semibold relative inline-block shadow-sm justify-center py-2 px-4 border border-transparent rounded text-white bg-teal-700 hover:bg-teal-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
          >
            Re-authenticate NationBuilder
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      <SuccessAlertBar>
        <strong>NationBuilder:</strong> you have successfully installed our App!
      </SuccessAlertBar>
      <People />
    </>
  );
};

export default NationBuilderOAuth;
