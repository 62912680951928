import React, { SyntheticEvent } from 'react';
import useNationBuilderFormState from './hooks/useNationBuilderFormState';

// Types
import { FormSubmitOnSuccessCallback } from './types';
import useUpdateDocumentTitle from '../../hooks/useUpdateDocumentTitle';

const pageTitle = 'NationBuilder';

const NationBuilder = () => {
  const goToNationBuilderAuthURL =
    React.useCallback<FormSubmitOnSuccessCallback>(({ authenticationURL }) => {
      window.location.replace(authenticationURL);
    }, []);

  const {
    formState,
    setFormState,
    submitForm,
    formSubmitStatus: status,
  } = useNationBuilderFormState({ onSuccess: goToNationBuilderAuthURL });

  useUpdateDocumentTitle(pageTitle);

  const onFormSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    submitForm(formState);
  };

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((formState) => ({
      ...formState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <form
      className="max-w-2xl mx-auto py-10 px-4 sm:px-6 lg:px-8"
      onSubmit={onFormSubmit}
    >
      <div className="grid grid-cols-1 gap-6 m-5 mb-7 text-left">
        <h1 className="text-3xl font-semibold text-center text-gray-600">
          Authenticate NationBuilder
        </h1>
        <p className="text-gray-600">
          This form requires information for an app that should already be
          created in NationBuilder.
        </p>
        <label className="block">
          <span className="text-gray-600">Nation Slug</span>
          <input
            onChange={onInputChangeHandler}
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring focus:ring-teal-500 focus:ring-opacity-50"
            placeholder=""
            required={true}
            name="nation_slug"
          />
        </label>
        <label className="block">
          <span className="text-gray-600">Client ID</span>
          <input
            onChange={onInputChangeHandler}
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring focus:ring-teal-500 focus:ring-opacity-50"
            placeholder=""
            required={true}
            name="client_id"
          />
        </label>
        <label className="block">
          <span className="text-gray-600">Client Secret</span>
          <input
            onChange={onInputChangeHandler}
            type="password"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring focus:ring-teal-500 focus:ring-opacity-50"
            placeholder=""
            required={true}
            name="client_secret"
          />
        </label>
      </div>
      <button
        type="submit"
        className="bg-teal-600 hover:bg-teal-500 text-white font-semibold py-2 px-6 rounded"
        disabled={status === 'loading'}
      >
        Authenticate
        {status === 'loading' ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline-block animate-spin ml-2 h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          ''
        )}
      </button>
    </form>
  );
};

export default NationBuilder;
