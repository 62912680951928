import React, { ReactNode } from 'react';
import LoadingAlertBar from '../../../../components/LoadingAlertBar';
import ErrorAlertBar from '../../../../components/ErrorAlertBar';
import { Link } from 'react-router-dom';

// Hooks
import usePeopleCount from './hooks/usePeopleCount';
import useSyncPeopleToContacts from './hooks/useSyncPeopleToContacts';

const People: React.FC = () => {
  const {
    data: peopleCountData,
    error: peopleCountError,
    status: peopleCountStatus,
  } = usePeopleCount();

  const { sync, syncStatus, data } = useSyncPeopleToContacts();

  if (peopleCountStatus === 'loading' || !peopleCountData) {
    return <LoadingAlertBar>Loading NB People data</LoadingAlertBar>;
  }

  if (peopleCountStatus === 'error' && peopleCountData) {
    return (
      <ErrorAlertBar>
        <span>
          {peopleCountData &&
            !peopleCountData.success &&
            peopleCountData.message}
        </span>
      </ErrorAlertBar>
    );
  }

  const onSyncButtonClickHandler = () => {
    sync();
  };

  return (
    <>
      <div className="p-6 pt-8">
        {(syncStatus === 'idle' || syncStatus === 'loading') && (
          <>
            <p className="block mb-6 text-lg text-slate-600">
              There are{' '}
              {peopleCountData && peopleCountData.success && (
                <strong>{peopleCountData.data.people_count}</strong>
              )}{' '}
              people on your nation.
            </p>
            <p className="block mb-8 text-lg text-slate-600">
              To initiate the sync please click the button below.
            </p>
            <button
              type="submit"
              className="bg-teal-700 mb-4 hover:bg-teal-800 text-white font-semibold py-2 px-6 rounded disabled:opacity-60 hover:disabled:bg-teal-700"
              onClick={onSyncButtonClickHandler}
              disabled={syncStatus === 'loading'}
            >
              Sync To Contacts
              {syncStatus === 'loading' && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-block animate-spin ml-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
          </>
        )}
        {data && !data.success && (
          <>
            <ErrorAlertBar>{data.message}</ErrorAlertBar>
            <p className="bg-red-100 my-6 mt-0 text-center p-2">
              Whoops! Something went wrong. Try{' '}
              <Link to={'/hubspot'} className="font-semibold underline">
                reinstalling our app
              </Link>{' '}
              and re-syncing NationBuilder People.
            </p>
          </>
        )}
        {data && data.success && (
          <div className="block my-6 text-left">
            <h3 className="text-2xl mb-2">Sync Status</h3>
            {data.data.errors.length > 0 && (
              <p className="bg-red-100 p-2 text-md">
                Looks like a few things went wrong during the sync. There may be
                duplicates in your HubSpot account. Try deleting contacts first
                and{' '}
                <Link to={'/hubspot'} className="font-semibold underline">
                  reinstalling our app
                </Link>{' '}
                or by clicking the{' '}
                <span className="font-semibold">Install</span> button in the
                header.
              </p>
            )}
            {data.data.errors.length === 0 && (
              <p className="bg-green-100 p-2 text-md">
                Sync was successful! You should see the newly imported "People"
                in your HubSpot Contacts.
              </p>
            )}
            <hr className="border-b-2 border-slate-100 my-5" />
            <h4 className="text-lg">
              People Synced:{' '}
              <span className="font-semibold">
                {data.data.peopleSynced.length}
              </span>
            </h4>
            {data.data.errors.length !== 0 && (
              <>
                <hr className="border-b-2 border-slate-100 my-3 mb-5" />
                <h4 className="text-lg font-semibold">Error Log:</h4>
                <hr className="border-b border-slate-100 my-1" />
                <div className={''}>
                  {data.data.errors.map((error, index) => {
                    return (
                      <span
                        key={index}
                        className="py-2 border-b border-slate-100 block"
                      >
                        {error}
                      </span>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default People;
