import React from 'react';

const ErrorAlertBar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="bg-red-100 text-center py-4 max-w-5xl m-5 mx-auto px-4 sm:px-6 rounded-lg">
      <div
        className="p-2 bg-red-400 items-center text-white leading-none lg:rounded-full flex lg:inline-flex"
        role="alert"
      >
        <span className="flex rounded-full bg-red-600 uppercase px-2 py-1 text-xs font-bold mr-3">
          Error
        </span>
        <span className="font-semibold mr-2 text-left flex-auto">
          {children}
        </span>
      </div>
    </div>
  );
};

export default ErrorAlertBar;
