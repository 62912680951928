import { useMutation } from '@tanstack/react-query';
import { API_ROUTES } from '../../../../../api';

// Types
import { APIResponse } from '../../../../../types';

interface PeopleSyncData {
  peopleSynced: {
    hubspot_contact_id: string;
    nationbuilder_people_id: number | string;
  }[];
  errors: string[];
}

const useSyncPeopleToContacts = () => {
  const {
    data,
    mutate: sync,
    error: syncError,
    status: syncStatus,
  } = useMutation(['people-sync'], () =>
    fetch(API_ROUTES.SYNC, {
      method: 'POST',
    }).then<APIResponse<PeopleSyncData>>((res) => res.json())
  );

  return { sync, data, syncStatus, syncError };
};

export default useSyncPeopleToContacts;
