import React from 'react';
import privacyPolicy from '../privacy-policy';

const PrivacyPolicy: React.FC = () => {
  return (
    <section>
      <div
        className="container max-w-7xl mx-auto py-8 md:py-16 text-left px-5"
        dangerouslySetInnerHTML={{ __html: privacyPolicy }}
      ></div>
    </section>
  );
};

export default PrivacyPolicy;
